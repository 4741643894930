import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';

const Div = styled.div`
  width: 600px;
`

const SolipsistsDoodles = ({ data }) => {
  return (
    <Layout>
      <Link to="/">Back</Link>
      <Div>
        <Img
          fluid={data.page01.childImageSharp.fluid}
          alt="Solipsist's Doodles page 1"
        />
      </Div>
    </Layout>
  );
};

export const query = graphql`
  query {
    page01: file(relativePath: { eq: "solipsists-doodles/page01.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SolipsistsDoodles;
